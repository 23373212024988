.carrier-table-scroll {
  --scrollbar-color: transparent;
  overflow: overlay;

  tr:last-child > td {
    border-bottom: 0;
  }
  &:hover,
  *:focus,
  *:focus-within {
    --scrollbar-color: #dededf !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 5px solid transparent;
    border-radius: 8px;
    box-shadow: 4px 0 0 6px var(--scrollbar-color) inset;
  }
  &::-webkit-scrollbar {
    display: block;
    height: 17px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    margin-right: 102px;
    margin-left: 185px;
    background-color: transparent;
  }
}
