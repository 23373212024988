#root-portal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(9, 5, 15, 0.25);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
