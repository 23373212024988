.ReactModal__Overlay--after-open {
  background-color: rgba(9, 5, 15, 0.25) !important;
  z-index: 99;
  backdrop-filter: blur(3px);
}

.ReactModal__Content--after-open {
  overflow: initial !important;
}

#main {
  padding: 16px;
  border-radius: 8px;
  background-color: #09050f;
  font-weight: 500;
}

.react-date-picker__wrapper {
  align-items: center;
  padding-left: 13px;
  padding-right: 16px;
  border-radius: 12px;
  font-weight: 400;
  border: 2px solid #dededf !important;
  font-family: Montserrat, system-ui, sans-serif;
}

.react-date-picker__button {
  padding: 0 !important;
}

.react-date-picker__calendar {
  top: 60px !important;
  left: 0 !important;
  font-weight: 500;
  font-size: 16px;
  font-family: Montserrat, system-ui, sans-serif;
  width: 488px !important;
  z-index: 50 !important;
}

.react-date-picker__inputGroup__divider {
  color: #918f93;
  font-weight: 600;
}

.react-date-picker__calendar--open {
  border-radius: 20px !important;
  height: fit-content !important;
  padding: 10px;
  background-color: #fdfdfd;
  box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
  -webkit-box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
  -moz-box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
}

.react-date-picker__inputGroup__input {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.react-date-picker__inputGroup__leadingZero {
  font-weight: 600 !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: white;
}

.react-calendar__navigation__label {
  font-family: Montserrat, system-ui, sans-serif;
  color: #312e36;
  border-radius: 8px;
}

.react-calendar__navigation button {
  min-width: 56px;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-weight: 700;
}

.react-calendar {
  width: 488px;
  padding: 18px;
  border: none !important;
}

.react-calendar__tile {
  margin: 10px 0 !important;
  height: 40px;
  font-family: Montserrat, system-ui, sans-serif;
  color: #312e36;
}

.react-calendar__tile--now {
  background-color: #dededf !important;
  height: 40px;
  border-radius: 8px;
  text-decoration: dotted;
}

.react-calendar__tile:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}

.react-calendar__tile--active {
  background: #6d4caf !important;
  border-radius: 8px;
  color: #fdfdfd;
}

.react-calendar__month-view__weekdays__weekday {
  margin: 10px 0;
  font-weight: 500;
  font-family: Montserrat, system-ui, sans-serif;
  text-transform: none;
  font-size: 16px;
}

.react-calendar__month-view__days__day {
  font-family: Montserrat, system-ui, sans-serif;
}

.react-calendar__month-view__days__day--weekend {
  color: #312e36 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__month-view {
  width: 424px;
}

.react-calendar__navigation {
  width: 424px;
  padding-bottom: 10px;
}

.react-calendar__viewContainer {
  border-top: 1px solid #dededf;
  padding-top: 10px;
  width: 400px;
  margin-left: 16px;
}

.react-calendar__month-view {
  width: 100%;
}

.react-calendar__month-view__weekdays {
  width: 424px;
  margin-left: -12px;
  font-weight: 500;
}

.react-calendar__month-view__days {
  width: 424px;
  margin-left: -11px;
}

.react-calendar__tile--hasActive {
  height: 66px;
}

.react-calendar__year-view__months__month {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__decade-view__years__year {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__century-view__decades__decade {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__navigation__arrow {
  border-radius: 8px;
}

.react-calendar__navigation__arrow:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}

.react-calendar__navigation__label:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}
