@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html,
  body {
    @apply text-body1 bg-neutral-8 text-neutral-3;
    font-family: Montserrat, system-ui, sans-serif;
    overflow: overlay;
  }
}
