.dlg {
  @apply bg-neutral-8;
  width: 420px;
  min-height: 174px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
  border: 1px solid;
  @apply border-neutral-7;
  border-radius: 16px;
  animation: showDialog 0.2s ease;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  &-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(9, 5, 15, 0.25);
  }

  &-btn {
    width: 180px;
    padding: 16px auto;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

// Add animation for dlg-component
.dlg-component {
  animation: showDialog 0.2s ease;
}

// Create animation showDialog
@keyframes showDialog {
  from {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
