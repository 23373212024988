/** Custom toast style*/
:root {
  --toastify-color-success: #3b994a !important;
  --toastify-color-error: #cf1e24 !important;
  --toastify-color-warning: #fff278 !important;
  --toastify-color-info: #09050f !important;
}

.Toastify__toast {
  margin-bottom: 16px !important;
  width: 453px !important;
}
.Toastify__toast-container {
  padding: 0 !important;
  width: auto !important;
}
.Toastify__toast--info {
  display: flex !important;
  align-items: center !important;
  width: 302px !important;
}
.Toastify__close-button {
  margin: 5px 8px !important;
}
.Toastify__toast-body {
  padding-right: 0;
}
