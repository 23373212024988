/*
 *  Custom - scrollbar
 */

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cfc9c9;
}

.cus-scrollbar::-webkit-scrollbar-track,
.small-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar,
.small-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar-thumb,
.small-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dededf;
}
.small-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.tabscroll::-webkit-scrollbar {
  display: none;
}
