button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:disabled {
    cursor: not-allowed !important;
  }
}

.btn {
  @apply text-hairline1;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 53px;
  min-height: 24px;
  padding: 18px 24px;
  border-radius: 12px;
  background-color: transparent;
  transition: all 0.2s ease;
  &:active {
    transform: scale(0.95);
  }

  &-outline {
    color: #523280;
    background-color: #fdfdfd;
    border-width: 2px;
    padding: 16px 22px;
    border-color: #523280;
    &:hover {
      color: #342052;
      background-color: #ebddff;
    }
    &:disabled {
      color: #a989d8;
      border-color: #a989d8;
      background-color: #fdfdfd;
    }
  }
  &-primary {
    color: #fdfdfd;
    background-color: #523280;
    &:hover {
      background-color: #342052;
    }
    &:disabled {
      color: #ebddff;
      background-color: #a989d8;
    }
  }

  // btn size sm
  &-sm {
    @apply text-hairline2;
    padding: 13px 16px;
  }
  &-sm-outline {
    @extend .btn-sm;
    padding: 11px 14px;
  }
  &-252 {
    width: 252px;
    padding-right: 0;
    padding-left: 0;
  }

  // btn typical
  &-cancel-upload {
    @apply text-hairline3;
    padding: 4px 6px;
    border-radius: 6px;
  }

  // icon prefix - suffix
  &-icon {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  &-icon-outline {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  &-icon-prefix {
    @extend .btn-icon;
    img,
    svg {
      margin-right: 12px;
    }
  }
  &-icon-suffix {
    @extend .btn-icon;
    img,
    svg {
      margin-left: 12px;
    }
  }
}
